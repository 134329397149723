// main: main.scss
@keyframes menuClose {
  from {
    height: 100vh;
    max-height: 100vh;
  }

  to {
    height: 15vh;
    max-height: 15vh;
  }
}
@-webkit-keyframes menuClose {
  from {
    height: 100vh;
    max-height: 100vh;
  }

  to {
    height: 15vh;
    max-height: 15vh;
  }
}
@keyframes menuOpen {
  from {
    height: 15vh;
    max-height: 15vh;
  }

  to {
    height: 100vh;
    max-height: 100vh;
  }
}
@-webkit-keyframes menuOpen {
  from {
    height: 15vh;
    max-height: 15vh;
  }

  to {
    height: 100vh;
    max-height: 100vh;
  }
}
$anim_duree: .5s;
.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  align-self: auto;
  padding-top: 2.34vw;
  display: flex;
  flex-direction: column;
  transition: padding-top .2s ease-in, background-color .5s ease-in, transform .5s ease-in;
  background-color: white;
  min-height: 8.35em;
}

.Header-Burger {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 6.64em;

}

.Header-Burger div {
  height: 100%;
  display: flex;

}

.Header-Burger div svg {
  width: 3em;
  height: 3em;
  margin: auto;

}

.Header-Burger div svg path {
  fill: #514c56;
  transition: fill .2s ease-in;

}

.Header > a {
  margin: auto;

}

.Header > a .Header-Logo {
  transition: height .2s ease-in, width .2s ease-in;
  max-width: auto;
  max-height: 6.64em;

}

.Header > a .Header-Logo-big {
  max-width: auto;
  max-height: 8.35em;

}

.Header-Social {
  position: absolute;
  right: 0;
  padding-right: 9em;
  height: 8.35em;
  display: flex;
  align-items: center;

}

.Header-Menu > ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.Header-Menu > ul > li {
  font-family: "exo - Bold";
  position: relative;
  text-align: center;
}

.Header-Menu > ul > li:not(:hover) ul {
  display: none;

}

.Header-Menu > ul > li > a {
  display: block;
  text-transform: uppercase;
  color: #514c56;
  text-decoration: none;
  transition: color .1s linear;
  height: 100%;

}

.Header-Menu > ul > li:hover {
  transition:.1s, ;
  border-bottom: 5px solid #53042b;
  cursor: pointer;

}

.Header-Menu > ul > li > a:hover {
  color: #53042b;
  cursor: pointer;

}

.Header-Menu > ul > li > ul {
  position: absolute;
  padding: 0;
  border-top: 1.8em solid transparent;
  background-clip: padding-box;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 150px;
  background-color: rgba(255, 255, 255, 0.65);
  color: #333;
  list-style: none;

}

.Header-Menu > ul > li > ul > li {
  font-family: "exo - Regular";
  position: relative;
  display: flex;

}

.Header-Menu > ul > li > ul > li > a {
  display: block;
  text-transform: uppercase;
  color: #514c56;
  text-decoration: none;
  margin: 0.5em auto;
  transition: color .1s ease-in;

}

.Header-Menu > ul > li > ul > li > a:hover {
  color: #53042b;
  cursor: pointer;

}

.Header-hidden {
  transform: translateY(-100%);

}

.Header-sticky {
  background-color: white;
  padding-top: 0.85vw;
  min-height: 6.64em;

}

.Header-sticky .Header-Burger svg path {
  fill: #808093;

}

.Header-sticky .Header-Social {
  height: 6.64em;

}

.Header-sticky .Header-Social > ul > li {
  color: #808093;

}

.Header-sticky .Header-Social > ul > li svg {
  width: 1em;

}

.Header-sticky .Header-Social > ul > li svg path {
  fill: #53042b;

}

.Header-sticky .Header-Menu > ul > li > a {
  color: #808093;

}

.Header-sticky .Header-Menu > ul > li > a:hover {
  color: #53042b;
  cursor: pointer;

}

@media all and (max-width: 992px) {
  .Header {
    padding-top: 0.85vw;
    display: flex;
    flex-direction: column;
    height: 15vh;
    max-height: 15vh;
    min-height: inherit;
    > a {
        margin-top: 0 !important;
    }
  }

  .Header-Burger {
    display: block;
    right: 1em;
  }

  .Header-Logo {
    max-width: 14.5vh !important;
    max-height: 14.5vh !important;
  }

  .Header nav {
    transition:$anim_duree;

  }

  .Header.menuOpen {
    background-color: white;
    height: 100vh;
    max-height: 100vh;
    animation-name: menuOpen;
    -webkit-animation: menuOpen $anim_duree 1;
    animation-duration: $anim_duree;
  }

  .Header.menuOpen.Header-hidden , .Header-hidden{
    transform: none;
  }

  .Header.menuOpen .Header-Burger svg path {
    fill: #808093;
  }

  .Header.menuOpen nav {
    opacity: 1 !important;
    z-index: 9999;
      > ul> li ,>div>div{
      height: auto;
      > a, >button{
        cursor: inherit;
        pointer-events: inherit;
        z-index: 9999;
      }
    }
  }

  .Header:not(.menuOpen) {
    animation-name: menuClose;
    -webkit-animation: menuClose $anim_duree 1;
    animation-duration: $anim_duree;
  }

  .Header:not(.menuOpen) nav {
    display: none;
    z-index: -9999;
    opacity: 1 !important;
      > ul> li ,>div>div{
      height: 0 !important;
      > a, >button{
        cursor: not-allowed;
        pointer-events: none;
        z-index: -9999;
      }
    }
  }

  .Header-Menu {
    flex-basis: 90%;
  }

  .Header-Menu > ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    margin: 0;
  }

  .Header-Menu > ul > li {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: inherit;
    padding: 0;
  }

  .Header-Menu > ul > li:hover {
    border: none;
  }

  .Header-Menu > ul > li > a {
    color: #808093;
  }

  .Header-Menu > ul > li > ul {
    position: relative;


  }

  .Header-Menu > ul > li:last-child {
    margin-top: auto;
  }

  .Header-Social {
    height: 6.64em;
    position: relative;
    margin-bottom: auto;
    padding: 0;
  }
}
